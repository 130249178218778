<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :headers="headers"
          :items="publishers"
          :search="search"
          title="Penerbit"
          subtitle="Halaman untuk menambah penerbit"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      persistent
      :width="600"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Penerbit': 'Edit Penerbit'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="publisher.name"
                label="Nama Penerbit"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
      persistent
      :width="600"
      :is-loading-button="isLoadingButton"
      header="Hapus Penerbit"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '../../components/ConfirmDialog.vue'
import DataTablePagination from '../../components/DataTablePagination.vue'
import MainCard from '../../components/MainCard.vue'
import ModalDialog from '../../components/ModalDialog.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'

export default {
  components: {
    DataTablePagination,
    SkeletonLoaderTable,
    ConfirmDialog,
    ModalDialog,
    MainCard,
  },
  data() {
    return {
      modalDialog: false,
      editFormDialog: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      search: '',
      dialog: '',
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Penerbit', value: 'name' },
        { text: 'Actions', value: 'actions' },
      ],
      publishers: [],
      publisher: {
        uuid: '',
        name: '',
      },
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        type: {},
      },
      filterQuery: {
        publisher: '',
      },
      formValid: false,
    }
  },
  watch: {
    publisher: {
      handler() {
        const valid = []
        const requiredField = ['name']
        Object.entries(this.publisher).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listPublisher(this.filterQuery)
      },
    },
  },
  mounted() {
    this.listPublisher()
  },
  methods: {
    showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
      this.resetForm()
    },
    resetForm() {
      this.publisher.name = ''
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    async listPublisher(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('publisher', { ...params, page: this.page }).then(
        ({ data }) => {
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
          this.publishers = data.data.map((publishers, index) => ({
            ...publishers,
            index: index + 1,
          }))
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async add() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add('publisher', this.publisher).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.modalDialog = false
          this.isLoadingButton = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listPublisher(this.filterQuery)
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      await this.$services.ApiServices.get('publisher', uuid).then(
        ({ data }) => {
          this.publisher = data.data
        },
        err => console.error(err),
      )
      this.modalDialog = true
    },
    async update() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update('publisher', this.publisher, this.publisher.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.modalDialog = false
          this.isLoadingButton = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listPublisher(this.filterQuery)
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.publisher.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('publisher', this.publisher.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listPublisher(this.filterQuery)
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    searchHandler(data) {
      this.filterQuery.publisher = data
      this.page = 1
      this.listPublisher(this.filterQuery)
    },
  },
}
</script>

<style>
</style>
