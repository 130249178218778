<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :headers="headers"
          :items="categories"
          :is-loading="isLoadingData"
          title="Kategori"
          subtitle="Halaman untuk menambah kategori"
          add-btn="Tambah Data"
          :actions-row="['delete', 'detail', 'edit']"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @detail-item="showDetail"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :header="dialog ==='add'? 'Tambah Kategori':'Edit Kategori'"
      :text-button="dialog ==='add'? 'Tambah':'Update'"
      @handler-button="handlerButton"
      @close-button="modalDialog = false"
      @close-modal="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-autocomplete
            v-if="dialog =='add'"
            v-model="parentCategory.category"
            label="Kategori"
            dense
            :items="categoriesAll"
            item-text="name"
            item-value="uuid"
            outlined
            @change="changeSubcategories"
          ></v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-if="subcategories.length >= 1 && dialog =='add'"
            v-model="parentCategory.subcategory"
            label="Sub Kategori"
            dense
            :items="subcategories"
            item-text="name"
            item-value="uuid"
            outlined
            @change="changeSubsubcategories"
          ></v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-if="subsubcategories.length >= 1 && dialog =='add'"
            v-model="parentCategory.subsubcategory"
            label="Sub Subkategori"
            dense
            :items="subsubcategories"
            item-text="name"
            item-value="uuid"
            outlined
            @change="changeSubsubsubcategories"
          ></v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-if="subsubsubcategories.length >= 1 && dialog =='add'"
            v-model="parentCategory.subsubsubcategory"
            label="Sub Subsubkategori"
            dense
            :items="subsubsubcategories"
            item-text="name"
            item-value="uuid"
            outlined
            @change="changeSubsubsubsubcategories"
          ></v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-if="subsubsubsubcategories.length >= 1 && dialog =='add'"
            v-model="parentCategory.subsubsubsubcategory"
            label="Sub Subsubsubkategori"
            dense
            :items="subsubsubsubcategories"
            item-text="name"
            item-value="uuid"
            outlined
          ></v-autocomplete>
        </div>
        <div>
          <v-text-field
            v-model="category.name"
            label="Nama Kategori"
            dense
            outlined
          ></v-text-field>
        </div>
        <div v-if="dialog =='add'">
          <FileInput
            :value="category.thumbnail"
            :prependicon="icons.mdiImageMultipleOutline"
            label="Thumbnail"
            class="pt-0 pb-3"
            @input="getFile"
          ></FileInput>
        </div>
        <div v-if="dialog =='edit'">
          <FileInput
            v-model="initialFile"
            dense
            outlined
            :prependicon="icons.mdiImageMultipleOutline"
            label="Thumbnail"
            @input="getFile"
          >
          </FileInput>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="editSubcategoryDialog"
      header="Edit Kategori"
      text-button="Update"
      @handler-button="updateSub"
      @close-button="editSubcategoryDialog = false"
      @close-modal="editSubcategoryDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="subcategory.name"
            label="Name"
            dense
            outlined
          ></v-text-field>
        </div>
        <div>
          <FileInput
            v-model="initialFileSub"
            dense
            outlined
            :prependicon="icons.mdiImageMultipleOutline"
            label="Thumbnail"
            @input="getFile"
          >
          </FileInput>
        </div>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Kategori"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>

    <ModalDialog
      :visible="detailDialog"
      persistent
      header="Detail Kategori"
      :width="600"
      :hidden-actions="false"
      @close-modal="detailDialog = false"
    >
      <template v-slot:body>
        <div class="header-left d-flex detail-category">
          <ul class="">
            <li>Nama Kategori</li>
            <li>Thumbnail</li>
          </ul>
          <ul class="ml-4">
            <li>: {{ category.name }}</li>
            <li>
              :
              <v-avatar>
                <v-img
                  alt="thumbnail"
                  class=""
                  :src="category.thumbnail"
                ></v-img>
              </v-avatar>
            </li>
          </ul>
        </div>
        <div
          v-if="category.sub_categories.length > 0"
          class="mt-5"
        >
          <p class="text-center">
            <b>Sub kategori</b>
          </p>
          <v-treeview
            activatable
            rounded
            hoverable
            color="primary"
            :items="category.sub_categories"
            item-key="uuid"
            item-text="name"
            item-children="sub_categories"
          >
            <template
              slot="label"
              slot-scope="{ item }"
            >
              <a
                class="d-flex justify-space-between align-center text-decoration-none"
                href="#"
              >
                {{ item.name }}
                <v-chip
                  color="primary"
                >
                  <a
                    class="color-white"
                    href=""
                    @click.prevent="editSubCategory(item.uuid)"
                  >Edit</a>
                </v-chip>
              </a>
            </template>
          </v-treeview>
        </div>
      </template>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          large
          @click="detailDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mdiImageMultipleOutline } from '@mdi/js'
import ConfirmDialog from '../../components/ConfirmDialog.vue'
import DataTablePagination from '../../components/DataTablePagination.vue'
import FileInput from '../../components/FileInput.vue'
import MainCard from '../../components/MainCard.vue'
import ModalDialog from '../../components/ModalDialog.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'

export default {
  components: {
    DataTablePagination,

    FileInput,
    SkeletonLoaderTable,
    MainCard,
    ModalDialog,
    ConfirmDialog,
  },
  data() {
    return {
      modalDialog: false,
      confirmDialog: false,
      detailDialog: false,
      dialog: '',
      editSubcategoryDialog: false,
      isLoadingButton: false,
      isLoadingTable: true,
      isLoadingData: false,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Kategori', value: 'name' },
        { text: 'Thumbnail', value: 'avatar' },
        { text: 'Aksi', value: 'actions' },
      ],
      categories: [],
      categoriesAll: [],
      category: {
        uuid: '',
        name: '',
        thumbnail: null,
        sub_categories: [],
      },
      parentCategory: {
        category: '',
        subcategory: '',
        subsubcategory: '',
        subsubsubcategory: '',
        subsubsubsubcategory: '',
      },
      subcategories: [],
      subcategory: {
        uuid: '',
        name: '',
        thumbnail: null,
      },
      subsubcategories: [],
      subsubcategory: {
        uuid: '',
        name: '',
      },
      subsubsubcategories: [],
      subsubsubcategory: {
        uuid: '',
        name: '',
      },
      subsubsubsubcategories: [],
      subsubsubsubcategory: {
        uuid: '',
        name: '',
      },
      filterQuery: {
        search: '',
      },
      icons: {
        mdiImageMultipleOutline,
      },
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.category.thumbnail === 'string') {
          return new File([this.category.thumbnail], this.category.thumbnail, { type: 'text/plain' })
        }

        return this.category.thumbnail
      },
      set() {
        return this.category.thumbnail
      },
    },
    initialFileSub: {
      get() {
        if (typeof this.subcategory.thumbnail === 'string') {
          return new File([this.subcategory.thumbnail], this.subcategory.thumbnail, { type: 'text/plain' })
        }

        return this.subcategory.thumbnail
      },
      set() {
        return this.subcategory.thumbnail
      },
    },
  },
  watch: {
    category: {
      handler() {
        const valid = []
        const requiredField = ['name']
        Object.entries(this.category).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listCategory(this.filterQuery)
      },
    },
    parentCategory: {
      handler() {
        Object.entries(this.parentCategory).forEach(([key, value]) => {
          if (value) this.category.uuid = value
        })
      },
      deep: true,
    },
  },
  async mounted() {
    this.isLoadingTable = true
    await this.listCategory()
    this.isLoadingTable = false
  },
  methods: {
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
      this.listCategoryAll()
      this.resetForm()
    },
    resetForm() {
      this.category.uuid = ''
      this.category.name = ''
      this.category.thumbnail = null
      this.subcategories = []
      this.subsubcategories = []
      this.subsubsubcategories = []
      this.subsubsubsubcategories = []
      this.parentCategory = {
        category: '',
        subcategory: '',
        subsubcategory: '',
        subsubsubcategory: '',
        subsubsubsubcategory: '',
      }
    },
    async listCategory(params = this.filterQuery) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('category', { ...params, page: this.page, parent: '1' }).then(
        ({ data }) => {
          this.categories = data.data.map((categories, index) => ({
            ...categories,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },

    async listCategoryAll(params = this.filterQuery) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('category', { ...params, page: this.page, per_page: 'all' }).then(
        ({ data }) => {
          this.categoriesAll = data.data.map((categories, index) => ({
            ...categories,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },
    async add() {
      this.dialog = 'add'
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('parent_category_uuid', this.category.uuid)
      this.formData.append('name', this.category.name)
      if (this.category.thumbnail) this.formData.append('thumbnail', this.category.thumbnail)
      await this.$services.ApiServices.add('category', this.formData).then(
        ({ data }) => {
          this.resetForm()
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listCategory()
      await this.listCategoryAll()
    },
    getFile(data) {
      this.category.thumbnail = data
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      await this.$services.ApiServices.get('category', uuid).then(
        ({ data }) => {
          this.category = data.data
          this.category.thumbnail = data.data.thumbnail === null ? null : data.data.thumbnail.split('/').pop()
        },
        err => console.error(err),
      )
      this.modalDialog = true
      this.detailDialog = false
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('name', this.category.name)
      if (typeof this.category.thumbnail !== 'string') {
        this.formData.append('thumbnail', this.category.thumbnail)
      }
      if (this.category.thumbnail === null) this.formData.delete('thumbnail')
      await this.$services.ApiServices.update('category', this.formData, this.category.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listCategory()
      await this.listCategoryAll()
    },
    async updateSub() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('name', this.subcategory.name)
      if (typeof this.category.thumbnail !== 'string') {
        this.formData.append('thumbnail', this.subcategory.thumbnail)
      }
      if (this.subcategory.thumbnail === null) this.formData.delete('thumbnail')
      await this.$services.ApiServices.update('category', this.formData, this.subcategory.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.editSubcategoryDialog = false
          this.resetForm()
        },
        err => console.error(err),
      )
      await this.listCategory()
      await this.listCategoryAll()

      this.detailDialog = false
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.category.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('category', this.category.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listCategory()
      await this.listCategoryAll()
    },
    async showDetail(uuid) {
      await this.$services.ApiServices.get('category', uuid).then(
        ({ data }) => {
          this.category = data.data
          this.detailDialog = true
        },
        err => console.error(err),
      )
    },
    async editSubCategory(uuid) {
      await this.$services.ApiServices.get('category', uuid).then(
        ({ data }) => {
          this.subcategory = data.data
          this.editSubcategoryDialog = true
        },
        err => console.error(err),
      )
    },
    closeHandler() {
      this.modalDialog = false
      this.detailDialog = false
    },
    changeSubcategories() {
      const category = this.categoriesAll.filter(item => item.uuid === this.parentCategory.category)
      this.subcategories = category.length > 0 ? [...category[0].sub_categories] : []
    },
    changeSubsubcategories() {
      const subcategory = []
      this.categoriesAll.forEach(item => {
        item.sub_categories.forEach(sub => {
          if (sub.uuid === this.parentCategory.subcategory) subcategory.push(sub)
        })
      })
      this.subsubcategories = subcategory.length > 0 ? [...subcategory[0].sub_categories] : []
    },
    changeSubsubsubcategories() {
      const subsubcategory = this.subsubcategories.filter(item => item.uuid === this.parentCategory.subsubcategory)
      this.subsubsubcategories = subsubcategory.length > 0 ? [...subsubcategory[0].sub_categories] : []
    },
    changeSubsubsubsubcategories() {
      const subsubsubcategory = this.subsubsubcategories.filter(
        item => item.uuid === this.parentCategory.subsubsubcategory.uuid,
      )
      this.subsubsubsubcategories = subsubsubcategory.length > 0 ? [...subsubsubcategory[0].sub_categories] : []
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    searchHandler(category) {
      //! ISSUE = masih menggunakan uuid searchnya
      this.filterQuery.search = category
      this.page = 1
      this.listCategory(this.filterQuery)
    },
  },
}
</script>

<style>
.detail-category ul li {
  list-style: none;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.color-white {
  color: white !important;
  text-decoration: none;
}
</style>
